<template>
   <div class="product-params">
      <div class="product-params__wrap" data-param="color">
         <h4 class="product-params__title">Цвета:</h4>

         <ul class="product-params__list">
            <li
               v-for="color in product.colors"
               :key="color.name"
               :style="{ '--color': color.value }"
               :title="color.name"
               class="product-params__item"
            ></li>
         </ul>
      </div>

      <div class="product-params__wrap" data-param="size">
         <h4 class="product-params__title">Размеры:</h4>

         <ul class="product-params__list">
            <li v-for="size in product.sizes" :key="size.name" class="product-params__item">
               {{ size.name }}
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
   export default {
      name: 'ProductParams',

      props: {
         product: { type: Object, required: true }
      }
   };
</script>
